<template>
	<div id="app" class="container-fluid p-0 d-flex flex-column sticky-footer-wrapper">
		<header>
			<Navigation />
		</header>

		<main role="main" class="flex-fill">
			<div v-if="this.$store.state.page.loading == 1" class="text-center mt-5 pt-5">
				<p><i class="fas fa-yin-yang fa-spin fa-4x gold"></i></p>
				<p>Loading...</p>
			</div>
			<div v-else class="p-2 p-sm-0">
				<router-view />
			</div>
		</main>

		<footer class="footer mt-auto py-1"></footer>
	</div>
</template>

<script>
import Navigation from './components/Navi.vue';
import axios from 'axios';
import * as Cookie from '@/assets/js/cookie.js';

export default {
	name: 'App',
	components: {
		Navigation,
	},
	data() {
		return {};
	},
	computed: {},
	mounted: function () {
		this.checkLoginStatus();
	},
	methods: {
		checkLoginStatus() {
			console.log('checkLoginStatus');

			this.$store.commit('tokenSet', Cookie.getCookie('user_session'));
			var user_token = this.$store.getters.userTokenGet;

			this.getProfile(user_token);

			if (this.$route.name == 'login' && this.$store.getters.userTokenGet !== null) {
				console.log('user is logged in ---------');
				this.$router.push('/');
			} else if (this.$route.name == 'reset_password' || this.$route.name == 'login') {
				this.$store.state.loading.status = 0;
			} else if (user_token === null || user_token === '') {
				console.log('no user---------');
				this.$router.push('/login');
			}
		},
		checkVersion() {
			var cookieVersion = Cookie.getCookie('version');
			var storeVersion = this.$store.getters.appVersion;
			if (cookieVersion !== storeVersion) {
				Cookie.setCookie('version', storeVersion, this.$store.state.env.app_domain, '3');
				/* so we can login for dev */
				Cookie.setCookie('version', storeVersion, 'localhost', '3');
				console.log('reload APP');
				location.reload();
			}
		},
		envSet() {
			console.log('envSet');
			console.log('ENV:' + process.env.NODE_ENV);
			const tld = window.location.origin.split('.').pop();
			console.log(tld);
			var env = {
				app_domain: 'iamconnected.com',
				api_url: 'https://api.iamconnected.com/',
				site_url: 'https://auth.iamconnected.com/',
			};
			if (tld == 'http://localhost:8080') {
				env = {
					app_domain: 'localhost',
					api_url: 'https://api.iamconnected.loc/',
					site_url: 'https://auth.iamconnected.loc/',
				};
			} else if (tld == 'loc') {
				env = {
					app_domain: 'iamconnected.loc',
					api_url: 'https://api.iamconnected.loc/',
					site_url: 'https://auth.iamconnected.loc/',
				};
			}

			this.$store.commit('envSet', env);

			var subdomain = Cookie.getCookie('SD');

			if (subdomain === '') {
				Cookie.setCookie('SD', 'auth', this.$store.state.env.app_domain, '30');
			}
		},
		getProfile(token) {
			console.log('getProfile:' + token);
			let _this = this;
			axios({
				url: _this.$store.state.env.api_url + 'auth/profileGet',
				params: {
					token: token,
					log_session: 1,
				},
			})
				.then(function (response) {
					if (response.data.status && response.data.status == 1) {
						_this.$store.commit('profileSet', response.data);
						Cookie.setCookie(
							'user_session',
							response.data.profile.session_id,
							_this.$store.state.env.app_domain,
							'30'
						);
					} else {
						Cookie.deleteCookie('user_session', _this.$store.state.env.app_domain);
						if (_this.$route.name != 'login') {
							_this.$router.push('/login');
						}
					}
				})
				.catch(function () {
					console.log('Error: getProfile.');
				});
		},
	},
	created() {
		this.envSet();
		document.title = this.$route.meta.title + ' - ' + this.$store.state.site.title;
		this.$store.commit('pageLoading', 0);
	},
	watch: {
		$route(to) {
			document.title = to.meta.title + ' - ' + this.$store.state.site.title;
			this.checkVersion();
		},
	},
};
</script>

<style scoped>
body,
.sticky-footer-wrapper {
	min-height: 100vh;
}
.flex-fill {
	flex: 1 1 auto;
}
</style>
