<template>
	<div class="form-signin">
		<div class="align-self-center">
			<b-card style="max-width: 22rem" class="mb-2">
				<b-card-text>
					<div class="mb-3 text-center">
						<img :src="this.$store.state.site.logo" alt="iAMc" width="auto" height="35" />
					</div>

					<b-form @submit.stop.prevent>
						<b-form-group id="password" description="">
							<b-form-input
								v-model="password"
								type="password"
								required
								placeholder="Enter new password"
								@keyup="checkInputs"
							></b-form-input>
							<div class="col-12" v-if="errors.password != ''">
								<small class="text-danger" v-text="errors.password"></small>
							</div>
						</b-form-group>

						<b-form-group id="password_confirm" description="">
							<b-form-input
								v-model="password_confirm"
								type="password"
								required
								placeholder="Confirm new password"
								@keyup="checkInputs"
							></b-form-input>
							<div class="col-12" v-if="errors.password_confirm != ''">
								<small class="text-danger" v-text="errors.password_confirm"></small>
							</div>
						</b-form-group>
					</b-form>

					<div v-if="processing == 0" class="text-center">
						<b-button block href="#" variant="primary" @click="doReset">
							<i class="fas fa-sign-in-alt"></i> Reset
						</b-button>
					</div>
					<div v-else class="text-center gold">
						<i class="fas fa-atom fa-spin fa-2x"></i>
					</div>

					<div class="text-center" v-if="errors.login != ''">
						<div class="col-12">
							<small :class="errors.login_class" v-text="errors.login"></small>
						</div>
					</div>
				</b-card-text>
			</b-card>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import * as Cookie from '@/assets/js/cookie.js';

export default {
	name: 'ResetPassword',
	props: {},
	data() {
		return {
			errors: {
				password: '',
				password_confirm: '',
			},
			hash: this.$router.app._route.params.hash,
			password: null,
			password_confirm: null,
			processing: 0,
		};
	},
	methods: {
		checkInputs() {
			let valid = 0;
			this.errors.password = null;
			this.errors.password_confirm = null;
			this.errors.login = '';

			if (this.password == '' || this.password === null) {
				this.errors.password = 'Enter a new Password';
			}
			if (this.password_confirm == '' || this.password_confirm === null) {
				this.errors.password_confirm = 'Confirm the new Password';
			}

			if (this.password !== this.password_confirm) {
				this.errors.password_confirm = 'Password & Confirm do not match';
			} else {
				valid = 1;
			}

			return valid;
		},
		doReset() {
			let _this = this;
			if (this.checkInputs() == 0) return false;

			_this.processing = 1;
			_this.errors.login_class = 'gold';
			_this.errors.login = 'Contacting the Server';

			let params = new URLSearchParams();
			params.append('password', this.password);
			params.append('hash', this.hash);

			axios({
				url: _this.$store.state.env.api_url + 'ResetProcess',
				params: params,
			})
				.then(function (response) {
					if (response.data.status == 1) {
						_this.errors.login = 'Logging you In...';
						_this.$store.commit('profileSet', response.data);
						Cookie.setCookie(
							'user_session',
							response.data.profile.session_id,
							_this.$store.state.env.app_domain,
							'30'
						);
						_this.getMenu(response.data.profile.session_id);
						_this.$router.push('/dashboard');
						console.log('reload...');
					} else {
						_this.errors.login = response.data.message;
						_this.$cookies.remove('user_session');
						_this.errors.login_class = 'text-danger';
						_this.processing = 0;
					}
				})
				.catch(function () {
					_this.processing = 0;
					console.log('doReset: error');
				});
		},
		getMenu(session_id) {
			let _this = this;
			axios({
				url: _this.$store.state.env.api_url + 'userMenuGet',
				params: {
					session_id: session_id,
				},
			})
				.then(function (response) {
					if (response.data.menu.status == 1) {
						_this.$store.commit('menuSet', response.data);
					}
				})
				.catch(function () {
					console.log('Error: getMenu.');
				});
		},
	},
	created() {
		this.$store.state.show_loading = 0;
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-signin {
	width: 100%;
	max-width: 350px;
	padding: 15px;
	margin: 0 auto;
	margin-top: 15%;
}
</style>
