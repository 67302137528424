<template>
	<div class="form-signin">
		<div class="align-self-center">
			<b-card style="max-width: 22rem" class="mb-2">
				<b-card-text>
					<div class="mb-3 text-center">
						<img
							:src="this.$store.state.site.logo"
							class="img-fluid"
							:alt="this.$store.state.site.title"
						/>
					</div>

					<div v-if="show_login == 1">
						<b-form @submit.stop.prevent>
							<b-form-group id="login_form" description="">
								<b-form-input
									v-model="email"
									type="email"
									required
									placeholder="Enter Email"
								></b-form-input>
								<div class="col-12" v-if="errors.email != ''">
									<small class="text-danger" v-text="errors.email"></small>
								</div>
							</b-form-group>

							<b-form-group id="password" description="">
								<b-form-input
									v-model="password"
									type="password"
									required
									placeholder="Enter Password"
								></b-form-input>
								<div class="col-12" v-if="errors.password != ''">
									<small class="text-danger" v-text="errors.password"></small>
								</div>
							</b-form-group>
						</b-form>

						<div v-if="processing == 0" class="text-center">
							<b-button block href="#" variant="primary" @click="doLogin">
								<i class="fas fa-sign-in-alt"></i> Login
							</b-button>
						</div>
						<div v-else class="text-center gold">
							<i class="fas fa-yin-yang fa-spin fa-4x"></i>
						</div>

						<div class="row small">
							<div class="col">
								<b-link
									@click="showRegister()"
									class="gold small"
									v-text="'Register'"
								></b-link>
							</div>
							<div class="col text-right">
								<b-link
									@click="showForgot()"
									class="gold small"
									v-text="'Forgot Password'"
								></b-link>
							</div>
						</div>
					</div>

					<div v-else-if="show_forgot == 1">
						<b-form @submit.stop.prevent>
							<b-form-group id="login_form" description="">
								<b-form-input
									v-model="email"
									type="email"
									required
									placeholder="Email"
								></b-form-input>
								<div class="col-12" v-if="errors.email != ''">
									<small class="text-danger" v-text="errors.email"></small>
								</div>
							</b-form-group>

							<div v-if="processing == 0" class="text-center">
								<b-button block href="#" variant="primary" @click="sendPassword">
									<i class="fas fa-share"></i> Forgot Password
								</b-button>
							</div>
							<div v-else class="text-center gold">
								<i class="fas fa-yin-yang fa-spin fa-4x"></i>
							</div>
						</b-form>

						<div class="row small">
							<div class="col">
								<b-link
									@click="showRegister()"
									class="gold small"
									v-text="'Register'"
								></b-link>
							</div>
							<div class="col text-right">
								<b-link @click="showLogin()" class="gold small" v-text="'Login'"></b-link>
							</div>
						</div>
					</div>

					<div v-else-if="show_register == 1">
						<p class="text-center">Registration is by Invite only</p>

						<div class="row small">
							<div class="col">
								<b-link @click="showLogin()" class="gold small" v-text="'Login'"></b-link>
							</div>
							<div class="col text-right">
								<b-link @click="showForgot()" class="gold small" v-text="'Forgot'"></b-link>
							</div>
						</div>
					</div>

					<div class="text-center" v-if="errors.login != ''">
						<div class="col-12">
							<small :class="errors.login_class" v-text="errors.login"></small>
						</div>
					</div>
				</b-card-text>
			</b-card>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import * as Cookie from '@/assets/js/cookie.js';

export default {
	name: 'LogInOutRegister',
	props: {},
	data() {
		return {
			errors: {
				email: '',
				email_forgot: '',
				first_name: '',
				last_name: '',
				password: '',
				login: '',
				login_class: 'text-danger',
			},
			email: null,
			password: null,
			processing: 0,
			register: {
				disabled: true,
				first_name: '',
				last_name: '',
				email: '',
			},
			show_forgot: 0,
			show_login: 1,
			show_register: 0,
		};
	},
	mounted() {},
	methods: {
		checkRegisterEmail() {
			let valid = 0;
			if (this.validEmail(this.register.email)) {
				valid = 1;
			}
			return valid;
		},
		checkInputs(type) {
			let valid = 0;
			this.errors.email = '';
			this.errors.password = '';
			this.errors.login = '';
			this.errors.first_name = '';
			this.errors.last_name = '';

			if (type == 'forgot') {
				if (this.email === '' || this.email === null) {
					this.errors.email = 'Enter an Email';
				} else if (!this.validEmail(this.email)) {
					this.errors.email = 'Enter an Valid Email';
				} else {
					valid = 1;
				}
			} else if (type == 'register') {
				if (!this.checkRegisterEmail(this.register.email)) {
					this.errors.email = 'Enter a valid email';
				} else if (this.register.first_name === '') {
					this.errors.first_name = 'Enter a valid first name';
				} else if (this.register.last_name === '') {
					this.errors.last_name = 'Enter a valid last name';
				} else {
					valid = 1;
				}

				if (valid === 1) {
					this.register.disabled = false;
				} else {
					this.register.disabled = true;
				}
			} else {
				if (this.email === '' || this.email === null) {
					this.errors.email = 'Enter an Email';
				} else if (!this.validEmail(this.email)) {
					this.errors.email = 'Enter an Valid Email';
				} else if (this.password == '' || this.password === null) {
					this.errors.password = 'Enter a Password';
				} else {
					valid = 1;
				}
			}

			return valid;
		},
		clearRegister() {
			this.register.email = '';
			this.register.first_name = '';
			this.register.last_name = '';
			this.register.disabled = true;
		},
		registerProcess() {
			console.log('register');
			let _this = this;

			_this.errors.login_class = '';
			_this.errors.login = 'Contacting the Server';

			if (!this.validEmail(this.register.email)) {
				this.errors.email = 'A valid Email is required';
			} else {
				this.errors.email = '';
				let params = new URLSearchParams();
				params.append('token', _this.$store.state.site.token);
				params.append('app_link', _this.$store.state.env.app_domain);
				params.append('site_title', _this.$store.state.site.title);
				params.append('email', _this.register.email);
				params.append('first_name', _this.register.first_name);
				params.append('last_name', _this.register.last_name);

				axios({
					url: _this.$store.state.env.api_url + 'auth/register',
					params: params,
				})
					.then(function (response) {
						if (response.data.status == 1) {
							_this.errors.login_class = 'text-success';
							_this.errors.login = response.data.message;
							_this.clearRegister();
						} else {
							_this.errors.login_class = 'text-danger';
							_this.errors.login = response.data.message;
						}
					})
					.catch(function () {});
			}
		},
		doLogin() {
			let _this = this;
			if (this.checkInputs() == 0) return false;

			_this.processing = 1;
			_this.errors.login_class = 'gold';
			_this.errors.login = 'Contacting the Server';

			let params = new URLSearchParams();
			params.append('email', this.email);
			params.append('password', this.password);

			axios({
				url: _this.$store.state.env.api_url + 'loginProcess',
				params: params,
			})
				.then(function (response) {
					console.log(response.data);
					if (response.data.status == 1) {
						_this.errors.login = 'Logging you In...';

						_this.$store.commit('profileSet', response.data);

						Cookie.setCookie(
							'user_session',
							response.data.profile.session_id,
							_this.$store.state.env.app_domain,
							'30'
						);

						/* so we can login for dev */
						Cookie.setCookie(
							'user_session',
							response.data.profile.session_id,
							'localhost',
							'30'
						);

						console.log(_this.$store.state.env.app_domain);

						if (Cookie.getCookie('user_session') == response.data.profile.session_id) {
							_this.errors.login = 'Redirecting...';
							var subdomain = Cookie.getCookie('SD');
							if (subdomain !== '') {
								subdomain += '.';
							}
							console.log(subdomain);
							var redirect = 'https://' + subdomain + _this.$store.state.env.app_domain;

							if (_this.$store.state.env.app_domain == 'localhost') {
								redirect = 'http://localhost:8080/';
							}

							console.log(redirect);
							window.location.replace(redirect);
						} else {
							_this.errors.login = 'Session data Error...';
							Cookie.deleteCookie('user_session', _this.$store.state.env.app_domain);
							_this.errors.login_class = 'text-danger';
							_this.processing = 0;
						}
					} else {
						_this.errors.login = response.data.message;
						Cookie.deleteCookie('user_session', _this.$store.env.state.app_domain);
						_this.errors.login_class = 'text-danger';
						_this.processing = 0;
					}
				})
				.catch(function () {
					_this.processing = 0;
					console.log('doLogin: error');
				});
		},
		sendPassword() {
			let _this = this;
			if (this.checkInputs('forgot') == 0) return false;

			_this.processing = 1;
			_this.errors.login_class = 'gold';
			_this.errors.login = 'Contacting the Server';

			let params = new URLSearchParams();
			params.append('email', this.email);

			axios({
				url: _this.$store.state.env.api_url + 'forgotPassword',
				params: params,
			})
				.then(function (response) {
					if (response.data.status == 1) {
						_this.errors.login = response.data.message;
						_this.processing = 0;
						_this.toggle_forgot_form(0);
						/*_this.$router.push('/');*/
					} else {
						_this.errors.login = response.data.message;
						_this.errors.login_class = 'text-danger';
						_this.processing = 0;
					}
				})
				.catch(function () {
					_this.processing = 0;
					console.log('sendPassword: error');
				});
		},
		showForgot() {
			this.show_forgot = 1;
			this.show_login = 0;
			this.show_register = 0;
		},
		showLogin() {
			this.show_forgot = 0;
			this.show_login = 1;
			this.show_register = 0;
		},
		showRegister() {
			this.show_forgot = 0;
			this.show_login = 0;
			this.show_register = 1;
		},
		validEmail(email) {
			var re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
	},
	created() {
		this.$store.state.show_loading = 0;
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-signin {
	width: 100%;
	max-width: 350px;
	padding: 15px;
	margin: 0 auto;
	margin-top: 12%;
}
</style>
