<template>
	<div class="container-fluid" id="sandpit_wrap">
		<div class="text-center mt-2">
			<div class="">
				<h3>Sandpit</h3>
			</div>

			{{ data }}
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Sandpit',
	components: {},
	data() {
		return {
			data: null,
		};
	},
	computed: {},
	methods: {
		getData() {},
	},
	mounted() {
		this.getData();
	},
	watch: {},
};
</script>

<style scoped></style>
