<template>
	<div id="subscriptions_wrapper" class="container">
		<div class="">
			<div class="">
				<div v-if="page_type === 'success'">
					<h5 class="col p-4 text-success">Payment success...</h5>
					<hr />
				</div>
				<!--
				<div v-if="page_type === 'cancelled'">
					<h5 class="col p-4 text-danger">Payment cancelled...</h5>
					<hr />
				</div>
-->

				<b-list-group flush>
					<b-list-group-item v-if="userHasSubscription === 1">
						<div class="row">
							<div class="col-md-3">Current subscription</div>
							<div class="col-md-9">
								<p v-if="subscription_active.title">
									<b v-text="subscription_active.title" class="gold"></b>
								</p>
								<a
									v-if="page_loading === false"
									:href="manageBillingUrl"
									class="btn btn-outline-secondary"
									variant="outline-secondary"
									@click="showLoading()"
									>Manage Subscription & Billing
								</a>
							</div>
						</div>
					</b-list-group-item>

					<b-list-group-item
						v-if="new_user === 1 || (userHasSubscription === 0 && new_user === 0)"
					>
						<div class="row">
							<div class="col-md-3">Start new subscription</div>
							<div class="col-md-9">
								<b-form-select
									v-if="subscription_options.length > 1"
									v-model="subscription_selected"
									:options="subscription_options"
								></b-form-select>
								<p v-else>
									<i class="fas fa-spinner fa-spin"></i>
									<small> loading products</small>
								</p>

								<stripe-checkout
									ref="checkoutRef"
									:pk="publishableKey"
									:items="subscription_items"
									:successUrl="successUrl"
									:cancelUrl="cancelUrl"
									:sessionId="stripe_session_id"
									:customer="stripe_customer_id"
								>
									<template slot="checkout-button">
										<span v-if="page_loading === true"></span>
										<b-button
											v-else
											variant="outline-primary"
											@click="checkout"
											:disabled="checkoutIsDisabled"
											>Start Subscription
										</b-button>
									</template>
								</stripe-checkout>
							</div>
						</div>
					</b-list-group-item>

					<b-list-group-item v-if="page_loading === true">
						<div class="text-center">
							<p><i class="fas fa-spinner fa-spin"></i></p>
							<p>Loading...</p>
						</div>
					</b-list-group-item>
				</b-list-group>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { StripeCheckout } from 'vue-stripe-checkout';
export default {
	name: 'SubscriptionOverview',
	components: {
		StripeCheckout,
	},
	data() {
		return {
			checkoutIsDisabled: true,
			customerEmail: this.$store.state.profile.email,
			dasboard: {},
			page_loading: true,
			publishableKey: null,
			successUrl: process.env.VUE_APP_SITE_URL + 'payments/subscriptions/success',
			cancelUrl: process.env.VUE_APP_SITE_URL + 'payments/subscriptions/cancelled',
			manageBillingUrl:
				process.env.VUE_APP_API_URL +
				'payments/manage_billing?token=' +
				this.$store.state.session_id,
			new_user: null,
			page_type: this.$route.params.page_type,
			section_title: this.$route.meta.title,
			stripe_session_id: null,
			stripe_customer_id: null,
			subscription_active: {},
			subscription_items: [],
			subscription_options: [{ value: null, text: 'Please select an option' }],
			subscription_selected: null,
			viewing_user_id: null,
			userHasSubscription: null,
		};
	},
	computed: {
		profile() {
			var P = this.$store.state.profile;
			this.sessionDataGet();
			return P;
		},
	},
	methods: {
		checkout() {
			console.log('checkout:');

			this.showLoading();

			let _this = this;

			let params = new URLSearchParams();
			params.append('token', _this.$store.state.session_id);
			params.append('successUrl', _this.successUrl);
			params.append('cancelUrl', _this.cancelUrl);
			params.append('subscription_selected', _this.subscription_selected);

			if (_this.stripe_customer_id !== null) {
				params.append('customer_id', _this.stripe_customer_id);
			}

			axios({
				url: _this.$store.state.env.api_url + 'payments/stripeCheckoutSessionCreate',
				params: params,
			})
				.then(function (response) {
					if (
						typeof response.data.result.session !== 'undefined' &&
						response.data.result.session !== null
					) {
						_this.stripe_session_id = response.data.result.session;
						(_this.successUrl =
							process.env.VUE_APP_SITE_URL +
							'payments/subscriptions/success?session_id=' +
							_this.stripe_session_id),
							_this.$refs.checkoutRef.redirectToCheckout();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		load_viewing_users_details() {
			let _this = this;

			if (this.viewing_user_id !== null) {
				console.log('load_viewing_users_details:' + this.viewing_user_id);
				_this.dasboard = {};
				let params = new URLSearchParams();
				params.append('token', _this.$store.state.session_id);
				params.append('user_id', _this.viewing_user_id);

				axios({
					url: _this.$store.state.env.api_url + 'memberGetProfile',
					params: params,
				})
					.then(function (response) {
						if (response.data.profile !== null) {
							console.log('add to dashboard');
							_this.dashboard = response.data.profile;
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		sessionDataGet() {
			console.log('sessionDataGet');
			let _this = this;

			let params = new URLSearchParams();
			params.append('token', _this.$store.state.token);

			if (this.$route.query.session_id) {
				params.append('session_id', _this.$route.query.session_id);
			}

			axios({
				url: _this.$store.state.env.api_url + 'payments/sessionDataGet',
				params: params,
			})
				.then(function (response) {
					if (response.data.result.stripe_public_key) {
						_this.publishableKey = response.data.result.stripe_public_key;
					}

					if (response.data.result.stripe_subscription_plans) {
						const entries = Object.values(response.data.result.stripe_subscription_plans);
						for (const product of entries) {
							var T = { value: product.id, text: product.title };
							_this.subscription_options.push(T);
						}
					}

					if (
						response.data.result.stripe_customer_id &&
						response.data.result.stripe_customer_id !== null
					) {
						_this.stripe_customer_id = response.data.result.stripe_customer_id;
					}

					_this.userHasSubscription = response.data.result.userHasSubscription;
					_this.new_user = response.data.result.new_user;
					_this.subscription_active = response.data.result.subscription_active;

					_this.page_loading = false;
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		showLoading() {
			this.page_loading = true;
		},

		subscriptionItemSet() {
			this.subscription_items = [
				{
					plan: this.subscription_selected,
					quantity: 1,
				},
			];
		},

		userHasActiveSubscription() {
			console.log('userHasActiveSubscription');
			let _this = this;

			let params = new URLSearchParams();
			params.append('token', _this.$store.state.session_id);

			axios({
				url: _this.$store.state.env.api_url + 'payments/userHasActiveSubscription_check',
				params: params,
			})
				.then(function (response) {
					_this.userHasSubscription = response.data.status;

					if (response.data.result.subscription) {
						_this.subscription_active.title = response.data.result.subscription.title;
						_this.subscription_active.expires = response.data.result.subscription.expires;
						_this.subscription_active.cost = response.data.result.subscription.cost;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	created() {},

	mounted() {
		this.sessionDataGet();
	},
	watch: {
		subscription_selected: {
			deep: true,
			immediate: true,
			handler(val) {
				if (val === null) {
					this.checkoutIsDisabled = true;
				} else {
					this.checkoutIsDisabled = false;
					this.subscriptionItemSet();
				}
			},
		},
	},
};
</script>
