import Vue from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';
import BootstrapVue from 'bootstrap-vue';

Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'vue-toast-notification/dist/theme-sugar.css';

new Vue({
	router,
	store,
	render: (h) => h(App),
	methods: {},
	created: function () {},
}).$mount('#app');

Vue.config.productionTip = false;
