function setCookie(name, value, domain, days) {
	const assign = name + '=' + escape(value) + ';';
	const d = new Date();
	d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + d.toUTCString() + ';';
	const path = 'path=/;';
	if (domain.substring(0, 9) == 'localhost') {
		domain = 'localhost';
	} else {
		domain = '.' + domain;
	}
	const domainOut = 'domain=' + domain;
	document.cookie = assign + expires + path + domainOut;
}
function getCookie(cname) {
	console.log('getCookieJS:' + cname);
	var name = cname + '=';
	var decodedCookie = decodeURIComponent(document.cookie);

	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function deleteCookie(cname, domain) {
	if (domain.substring(0, 9) == 'localhost') {
		domain = 'localhost';
	} else {
		domain = '.' + domain;
	}
	const domainOut = 'domain=' + domain;
	var C =
		cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Max-Age=-99999999;' + domainOut;
	document.cookie = C;
}
console.log('CookieJS');
export { setCookie, getCookie, deleteCookie };
