<template>
	<div class="" id="profile_wrapper">
		<div class="row">
			<div class="col-12">
				<div class="form-group row">
					<label class="col-lg-3 col-form-label">Name</label>
					<div class="col-lg-4 mb-1">
						<input
							type="text"
							v-model="profile.first_name"
							class="form-control"
							id="first_name"
							placeholder="First Name"
							@keyup="checkFormValid($event)"
							required
						/>
						<small
							v-if="errors.first_name != ''"
							v-text="errors.first_name"
							class="text-danger"
						></small>
					</div>
					<div class="col-lg-4 mb-1">
						<input
							type="text"
							v-model="profile.last_name"
							class="form-control"
							id="last_name"
							placeholder="Last Name"
							@keyup="checkFormValid($event)"
							required
						/>
						<small
							v-if="errors.last_name != ''"
							v-text="errors.last_name"
							class="text-danger"
						></small>
					</div>
					<label class="col-lg-3 col-form-label">User Name</label>
					<div class="col-lg-4 mb-1">
						<input
							type="text"
							v-model="profile.user_name"
							class="form-control"
							id="user_name"
							placeholder="User Name"
							@keyup="checkFormValid($event)"
							required
						/>
						<small
							v-if="errors.user_name != ''"
							v-text="errors.user_name"
							class="text-danger"
						></small>
					</div>
				</div>

				<div class="form-group row">
					<label class="col-lg-3 col-form-label">Email</label>
					<div class="col-lg-6 mb-1">
						<input
							type="email"
							v-model="profile.email"
							class="form-control"
							id="email"
							placeholder="Email"
							@keyup="checkFormValid($event)"
						/>
						<small
							v-if="errors.email != ''"
							v-text="errors.email"
							class="text-danger"
						></small>
					</div>
				</div>

				<div class="form-group row">
					<label class="col-lg-3 col-form-label">Phone</label>
					<div class="col-lg-6 mb-1">
						<input
							type="text"
							v-model="profile.phone"
							class="form-control"
							id="phone"
							placeholder="Phone"
							@keyup="checkFormValid($event)"
						/>
						<small
							v-if="errors.phone != ''"
							v-text="errors.phone"
							class="text-danger"
						></small>
					</div>
				</div>

				<div class="form-group row">
					<label for="UTCOffset" class="col-lg-3 col-form-label">
						Timezone Offset (hours)
					</label>
					<div class="col-lg-9 mb-1">
						<div v-if="profile.timezoneOffsetH === null">
							<h3 class="text-danger">
								Set your timezone
								<small>Then press the update profile submit button</small>
							</h3>
						</div>
						<timezone-select :profile="profile" @changed="timezoneChange" />
						<small
							v-if="errors.timezoneOffsetH != ''"
							v-text="errors.timezoneOffsetH"
							class="text-danger"
						></small>
					</div>
				</div>

				<div class="form-group row mb-1">
					<label class="col-lg-3 col-form-label">Password</label>
					<div class="col-lg-4 mb-1">
						<input
							type="password"
							v-model="password"
							class="form-control"
							id="password"
							placeholder="Password"
							@keyup="checkPassword($event)"
						/>
					</div>
					<div class="col-lg-4 mb-1">
						<input
							type="password"
							v-model="password_confirm"
							class="form-control mb-1"
							id="password_confirm"
							placeholder="Confirm Password"
							@keyup="checkPassword($event)"
						/>
						<small
							v-if="errors.password != ''"
							v-text="errors.password"
							class="text-danger"
						></small>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="text-center">
					<div v-if="errors.profile != ''" class="mb-2">
						<small v-html="errors.profile"></small>
					</div>
					<button
						type="button"
						class="btn"
						:class="btn_update_class"
						@click="updateProfile($event)"
						:disabled="!disableSubmit"
					>
						<i class="fas" :class="btn_update_icon"></i>
						Update
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import TimezoneSelect from '../components/TimezoneSelect.vue';
export default {
	name: 'Profile',
	components: {
		TimezoneSelect,
	},
	data() {
		return {
			btn_update_class: 'btn-secondary',
			btn_update_icon: 'fa-pen',
			disableSubmit: false,
			errors: {
				email: '',
				phone: '',
				first_name: '',
				last_name: '',
				user_name: '',
				password: '',
				profile: '',
				timezoneOffsetH: '',
			},
			password: '',
			password_confirm: '',
			settings_selected: [],
			settings_options: [
				{ text: 'Red', value: 'red' },
				{ text: 'Green', value: 'green' },
				{ text: 'Yellow (disabled)', value: 'yellow', disabled: true },
				{ text: 'Blue', value: 'blue' },
			],
		};
	},
	computed: {
		profile() {
			return this.$store.state.profile;
		},
	},
	methods: {
		checkEmail() {
			let _this = this;
			if (!this.validEmail(this.profile.email)) {
				this.errors.email = 'A valid Email is required';
			} else {
				this.errors.email = '';
				let params = new URLSearchParams();
				params.append('token', _this.$store.state.token);
				params.append('email', _this.profile.email);

				axios({
					url: _this.$store.state.env.api_url + 'emailCheck',
					params: params,
				})
					.then(function (response) {
						if (response.data === null) {
							_this.errors.email = 'Email is already in use';
						} else {
							_this.errors.email = '';
						}
						_this.test = 9;
					})
					.catch(function () {});
			}
		},

		checkFormValid(e) {
			this.errors.profile = '';
			var required = e.target.required;
			var id = e.target.id;
			var val = e.target.value;
			const errors = Object.keys(this.errors);

			/* set errors */
			this.errors.profile = '';
			for (const key of errors) {
				if (key === id && required == true) {
					if (val === '') {
						this.errors[key] = key + ' is required';
					} else this.errors[key] = '';
				}

				if (key === 'user_name') {
					this.checkUsername();
				} else if (key === 'email') {
					this.checkEmail();
				}
				this.disableBtnSet();
			}
		},
		checkPassword() {
			this.errors.profile = '';
			var val = false;

			if (this.password != this.password_confirm) {
				this.errors.password = 'Passwords do not match!';
				val = false;
			} else {
				this.errors.password = '';
				val = true;
			}
			this.disableBtnSet();
			return val;
		},
		checkUsername() {
			let _this = this;
			let params = new URLSearchParams();
			params.append('token', _this.$store.state.token);
			params.append('user_name', _this.$store.state.profile.user_name);

			axios({
				url: _this.$store.state.env.api_url + 'userNameCheck',
				params: params,
			})
				.then(function (response) {
					if (response.data === null) {
						_this.errors.user_name = 'Username is already in use';
					}
				})
				.catch(function () {});
		},
		disableBtnSet() {
			var form_is_valid = true;
			const E = Object.entries(this.errors);

			for (const obj of E) {
				if (obj[1] !== '') {
					form_is_valid = false;
					break;
				}
			}

			if (form_is_valid == true) {
				this.disableSubmit = true;
				this.btn_update_class = 'btn-success';
				this.btn_update_icon = 'fa-hand-point-right';
			} else {
				this.disableSubmit = false;
				this.btn_update_class = 'btn-secondary';
				this.btn_update_icon = 'fa-pen';
			}
		},

		timezoneChange(val) {
			console.log(val);
			if (val == 0) {
				this.errors.timezoneOffsetH = 'select a Timezone';
			} else {
				this.errors.profile = '';
				this.errors.timezoneOffsetH = '';
				this.disableBtnSet();
			}
		},
		updateProfile(e) {
			var form_is_valid = this.checkFormValid(e);
			if (form_is_valid == false) return false;
			if (this.checkPassword == false) return false;

			this.updateSend();
		},
		/*updateSecurity() {
			if (this.checkPassword == false) return false;
			this.updateSend();
		},*/
		updateSend() {
			let _this = this;

			this.errors.profile = '<i class="fas fa-atom fa-spin fa-2x"></i>';

			let params = new URLSearchParams();
			params.append('token', _this.$store.state.token);
			params.append('member_id', _this.profile.id);
			params.append('first_name', _this.profile.first_name);
			params.append('last_name', _this.profile.last_name);
			params.append('user_name', _this.profile.user_name);
			params.append('email', _this.profile.email);
			params.append('phone', _this.profile.phone);
			params.append('password', _this.password);
			params.append('timezoneOffsetH', _this.profile.timezoneOffsetH);

			axios({
				url: _this.$store.state.env.api_url + 'profileUpdate',
				params: params,
			})
				.then(function (response) {
					if (response.data.status == 1) {
						_this.disableSubmit = false;
						_this.btn_update_class = 'btn-secondary';
						_this.btn_update_icon = 'fa-pen';
						_this.$store.commit('profileSet', response.data);
					}
					_this.errors.profile = response.data.message;
				})
				.catch(function (e) {
					console.log('Error: updateSend:' + e);
				});
		},
		validEmail(email) {
			var re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			var val = re.test(email);
			console.log(email + ':' + val);
			return val;
		},
	},
	mounted() {},
	watch: {
		errors: {
			deep: true,
			handler() {
				this.disableBtnSet();
			},
		},
	},
};
</script>

<style scoped>
.col-form-label {
	color: #d1af60 !important;
}
</style>
