import Vue from 'vue';
import VueRouter from 'vue-router';
import Error404 from '../views/Error404.vue';

import Dashboard from '../views/Dashboard.vue';
import Devices from '../views/Devices.vue';

import LogInOutRegister from '../views/LogInOutRegister.vue';
import ResetConfirmPassword from '../views/ResetConfirmPassword.vue';
import Sandpit from '../views/Sandpit.vue';
import Settings from '../views/Settings.vue';
import Subscriptions from '../views/Subscriptions.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		alias: '/dashboard',
		name: 'home',
		component: Dashboard,
		meta: {
			title: 'Dashboard',
			min_level: 'member',
		},
	},

	{
		path: '/app',
		name: 'app',
		beforeEnter() {
			window.open('https://app.iamconnected.com', '_self');
		},
	},

	{
		path: '/devices',
		name: 'devices',
		component: Devices,
		meta: {
			title: 'My Devices',
		},
	},

	{
		path: '/forum',
		name: 'forum',
		beforeEnter() {
			window.open('https://forum.iamconnected.com', '_self');
		},
	},

	{
		path: '/login',
		name: 'login',
		component: LogInOutRegister,
		meta: {
			title: 'Authenticate',
			footer: 'hide',
			menu: 'hide',
		},
	},

	{
		path: '/profile',
		name: 'profile',
		component: Dashboard,
		meta: {
			title: 'My Profile',
		},
	},

	{
		path: '/resetPassword/:hash',
		alias: '/accountConfirm/:hash',
		name: 'reset_password',
		component: ResetConfirmPassword,
		meta: {
			title: 'Reset/Confirm Password',
			footer: 'hide',
		},
	},

	{
		path: '/sandpit',
		name: 'sandpit',
		component: Sandpit,
		meta: {
			title: 'Sandpit',
		},
	},

	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		meta: {
			title: 'My Settings',
		},
	},

	{
		path: '/subscriptions',
		name: 'subscriptions',
		component: Subscriptions,
		meta: {
			title: 'Subscriptions',
		},
	},

	{
		path: '*',
		name: '404',
		component: Error404,
		meta: {
			title: '404 - Missing page',
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

export default router;
