<template>
	<div class="container" id="devices_wrapper">
		<div class="">
			<b-list-group v-if="devices !== null" class="">
				<b-list-group-item v-for="item in devices" :key="item.id" class="">
					<div v-if="item.slug === 'fitbit'" class="">
						<label v-text="item.title" class="w-100 gold"></label>

						<div class="row">
							<div class="col-md-9 text-left small"></div>

							<div class="col-md-3 text-center">
								<div>
									<button
										v-if="item.processing === 0"
										@click="fetchLatestData(item)"
										class="btn btn-outline-primary btn-block"
									>
										Get Latest Data
									</button>
									<div v-else>
										<i class="fas fa-spinner fa-spin"></i>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="item.slug === 'focusband' || item.slug === 'ouraring'" class="">
						<label v-text="item.title" class="w-100 gold"></label>
						<div class="row">
							<div class="col-md-9 text-left">
								<div class="input-group">
									<input
										type="text"
										class="form-control"
										v-model="item.value"
										:placeholder="item.token_name"
									/>
									<div class="input-group-append">
										<div v-if="item.processing === 0" class="">
											<button
												@click="updateDevice(item)"
												class="btn btn-outline-primary btn-block m-0"
												type="button"
											>
												Update
											</button>
										</div>
										<div v-else class="">
											<i class="fas fa-spinner fa-spin p-2"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-3 text-center">
								<div v-if="item.processing === 0" class="">
									<button
										@click="fetchLatestData(item)"
										class="btn btn-outline-primary btn-block"
										type="button"
									>
										Get Latest Data
									</button>
								</div>
								<div v-else class="">
									<i class="fas fa-spinner fa-spin p-2"></i>
								</div>
							</div>
						</div>
					</div>

					<div v-if="item.slug === 'muse'" class="">
						<label v-text="item.title" class="w-100 gold"></label>
						CSV upload -
						<small>You do not have permissions to upload your own CSV data</small>
					</div>

					<div class="col-md-12 text-right text-secondary small">
						<b>Checked:</b> <span v-text="item.last_checked"></span><br />
						<b>Updated:</b> <span v-text="item.last_updated"></span>
					</div>

					<div v-if="item.message" class="text-right">
						<small class="text-warning" v-text="item.message"></small>
					</div>
				</b-list-group-item>
			</b-list-group>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Devices',
	components: {},
	data() {
		return {
			errors: {
				loading: '',
			},
			devices: null,
		};
	},
	computed: {
		profile() {
			return this.$store.state.profile;
		},
	},
	methods: {
		fetchLatestData(item) {
			console.log('fetchLatestData');
			console.log(item);
			if (item.slug === 'fitbit') {
				this.fitbitAuth(item);
			} else if (item.slug === 'focusband') {
				this.focusbandFetchData(item);
			}
		},
		fitbitAuth(item) {
			/* Add the user_id */
			item.processing = 1;
			var link = item.auth_link + this.profile.session_id;
			console.log(link);
			window.location.href = link;
		},
		fitbitFetchData(item) {
			item.processing = 1;
			console.log('fitbitFetchData');
			let _this = this;
			let params = new URLSearchParams();
			params.append('token', this.$store.getters.userTokenGet);
			params.append('user_id', this.profile.id);

			axios({
				url: _this.$store.state.env.api_url + 'fitbitUserData_Fetch',
				params: params,
			})
				.then(function (response) {
					item.processing = 0;
					item.message = response.data.message;
					if (response.data.status === 1) {
						item.last_updated = response.data.last_updated;
					}
				})
				.catch(function (e) {
					console.log('Error userDevicesGet:' + e);
				});
		},
		focusbandFetchData(item) {
			item.processing = 1;
			console.log('focusbandFetchData');
			let _this = this;
			let params = new URLSearchParams();
			params.append('token', this.$store.getters.userTokenGet);
			params.append('user_id', this.profile.id);
			params.append('focusband_user_id', item.value);

			axios({
				url: _this.$store.state.env.api_url + 'focusbandUserData_Fetch',
				params: params,
			})
				.then(function (response) {
					item.processing = 0;
					item.message = response.data.message;
					if (response.data.status === 1) {
						item.last_updated = response.data.last_updated;
					}
				})
				.catch(function (e) {
					console.log('Error userDevicesGet:' + e);
				});
		},
		userDevicesGet() {
			let _this = this;
			let params = new URLSearchParams();
			params.append('token', this.$store.getters.userTokenGet);
			params.append('user_id', this.profile.id);

			axios({
				url: _this.$store.state.env.api_url + 'userDevicesGet',
				params: params,
			})
				.then(function (response) {
					_this.errors.loading = response.data.message;

					if (response.data.status === 1) {
						if (response.data.devices) {
							_this.devices = response.data.devices;
						}
					}
				})
				.catch(function (e) {
					console.log('Error userDevicesGet:' + e);
				});
		},
		updateDevice(item) {
			let _this = this;
			item.processing = 1;
			item.message = '';
			let params = new URLSearchParams();
			params.append('token', this.$store.getters.userTokenGet);
			params.append('user_id', this.profile.id);

			const I = Object.entries(item);

			for (const [key, val] of I) {
				if (key !== 'text') {
					params.append('item[' + key + ']', val);
				}
			}

			axios({
				method: 'post',
				url: _this.$store.state.env.api_url + 'userDeviceUpdate',
				data: params,
			})
				.then(function (response) {
					item.processing = 0;
					item.message = response.data.message;
				})
				.catch(function (e) {
					console.log('Error updateDevice:' + e);
				});
		},
	},
	mounted() {
		this.userDevicesGet(this.$store.state.profile);
	},
	watch: {
		profile: {
			deep: true,
			handler(v) {
				if (v.id) {
					this.userDevicesGet(this.$store.state.profile);
				}
			},
		},
	},
};
</script>

<style scoped>
::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #f0939a !important;
	opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #f0939a !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #f0939a !important;
}
</style>
