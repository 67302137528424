import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		appVersion: '0.0.1',
		env: {
			app_domain: null,
			api_url: null,
			site_url: null,
		},
		site: {
			logo: 'https://media.iamconnected.com/images/iac_oc_logo-gold.png',
			title: 'iAMconnected',
			token: null,
		},
		tables: [],
		loading: {
			status: 0,
		},
		menu: {},
		page: {
			alert: {
				link: null,
				text: null,
				variant: null,
			},
			loading: 1,
			settings: null,
		},
		profile: {},
		search: [],
		token: null,
	},
	mutations: {
		alertSet(state, data) {
			this.state.page.alert = data;
		},
		appUrlSet(state, data) {
			this.state.api_url = data;
		},
		envSet(state, data) {
			this.state.env.app_domain = data.app_domain;
			this.state.env.api_url = data.api_url;
			this.state.env.site_url = data.site_url;
		},
		menuSet(state, data) {
			this.state.menu = data;
		},
		pageSettingsSet(state, data) {
			this.state.page.settings = data;
		},
		pageLoading(state, data) {
			console.log('pageLoading:' + data);
			this.state.page.loading = data;
		},
		profileSet(state, data) {
			this.state.profile = data.profile;
			this.state.token = data.profile.session_id;
		},
		searchSet(state, data) {
			const results = {};
			const entries = Object.entries(data);

			for (const obj of entries) {
				if (obj[1]) {
					results[obj[0]] = Object.values(obj[1]);
				}
			}

			this.state.search = results;
		},
		siteSet(state, data) {
			this.state.site = data;
		},
		tablesSet(state, data) {
			this.state.tables = [];
			if (data.results) {
				const entries = Object.values(data.results);
				for (const obj of entries) {
					this.state.tables.push(obj);
				}
			}
		},
		tokenSet(state, token) {
			this.state.token = token != '' && token !== 'null' ? token : null;
		},
	},
	computed: {},
	actions: {},
	modules: {},
	getters: {
		appVersion: (state) => {
			return state.appVersion;
		},
		menuGet: (state) => {
			return state.menu;
		},
		notificationsNumGet: (state) => {
			return state.menu.notifications;
		},
		pageSettingsGet: (state) => {
			return state.page.settings;
		},
		searchGet: (state) => (type) => {
			return state.search[type];
		},
		userLevelGet: (state) => {
			return parseInt(state.profile.level);
		},
		userTokenGet: (state) => {
			return state.token;
		},
	},
});

export default store;
