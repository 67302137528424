<template>
	<div>
		<div v-if="this.$store.state.profile.status" class="" id="navi_wrapper">
			<b-navbar toggleable="md" type="light" variant="gold" class="p-0 pr-2 pl-2 border-bottom">
				<b-navbar-brand href="/">
					<img
						:src="$store.state.site.logo"
						class="img-fluid logo"
						:alt="$store.state.site.title"
					/>
				</b-navbar-brand>

				<b-navbar-toggle target="nav-collapse" class=""></b-navbar-toggle>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="">
						<router-link
							to="/profile"
							class="btn btn-outline-primary"
							@click.native="naviClick"
						>
							<i class="fas fa-user"></i> Profile
						</router-link>

						<router-link
							to="/devices"
							class="btn btn-outline-primary"
							@click.native="naviClick"
						>
							<i class="fas fa-plug"></i> Devices
						</router-link>

						<router-link
							to="/settings"
							class="btn btn-outline-primary"
							@click.native="naviClick"
						>
							<i class="fas fa-sliders-h"></i> Settings
						</router-link>

						<router-link
							to="/subscriptions"
							class="btn btn-outline-primary"
							@click.native="naviClick"
						>
							<i class="fas fa-file-invoice-dollar"></i> Subscriptions
						</router-link>

						<b-dropdown text="Dropdown Button">
							<template #button-content> <i class="fas fa-cogs"></i> Sections </template>
							<b-dropdown-item>
								<router-link to="/app"><i class="fas fa-cog"></i> App</router-link>
							</b-dropdown-item>
							<b-dropdown-item>
								<router-link to="/forum"><i class="fas fa-cog"></i> Forum</router-link>
							</b-dropdown-item>
						</b-dropdown>
					</b-navbar-nav>

					<!-- Right aligned nav items -->
					<b-navbar-nav class="ml-auto">
						<b-nav-item @click="logOut()" class="btn btn-outline-primary logout_btn">
							<small>logout</small> <i class="fas fa-sign-out-alt fa-1x"></i>
						</b-nav-item>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>

			<div class="mb-3">
				<div class="row m-0">
					<div v-if="this.$store.getters.userLevelGet >= 90" class="col m-3">
						<b-alert variant="danger" show>
							<small v-text="this.$route.meta.title" class=""></small>:
							<b v-text="$store.state.profile.user_name"></b>
							<b-form-select v-model="users" :options="user_options"></b-form-select>
						</b-alert>
					</div>
					<div v-else class="col text-left border-bottom">
						<b v-text="this.$route.meta.title" class=""></b>:
						<small v-text="$store.state.profile.user_name"></small>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="!this.$route.meta.menu" class="text-center">
			<div class="pt-2 gold">
				<i class="fas fa-spinner fa-spin"></i><br />
				<small>loading navigation</small>
			</div>
		</div>
	</div>
</template>

<script>
import * as Cookie from '@/assets/js/cookie.js';
export default {
	name: 'Navi',
	components: {},
	data() {
		return {
			users: null,
			user_options: [{ value: null, text: 'Use my profile' }],
		};
	},
	computed: {},
	methods: {
		logOut() {
			var vars = {
				profile: {
					status: 0,
				},
			};
			this.$store.commit('profileSet', vars);

			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				Cookie.deleteCookie(cookies[i].split('=')[0], this.$store.state.env.app_domain);
			}

			this.$router.push('/login');
		},
		naviClick() {
			console.log('naviClick');
			this.$store.commit('pageLoading', 0);
		},
	},
	mounted() {},
	watch: {
		/*errors: {
			deep: true,
			handler() {
				this.disableBtnSet();
			},
		},*/
	},
};
</script>

<style scoped>
.bg-gold {
	background-color: #fff !important;
	color: #d1af60 !important;
}
.logo {
	max-height: 40px;
}
.logout_btn a {
	text-align: center;
}
.logout_btn {
	padding: 0;
}

@media (max-width: 767px) {
	.logout_btn {
		margin: 20px;
	}
}
#nav-collapse .btn {
	text-align: left;
}
</style>
