<template>
	<div class="container" id="authDashboard">
		<div v-if="this.$route.name === 'profile'" class="">
			<profile />
		</div>

		<div v-else>
			<div class="row mt-3">
				<div class="col-12 col-md-6 mb-2 text-center">
					<b-card header="the iAMconnected App" header-tag="header">
						<b-card-text>Checkout your progress at<br />the iAMconnected App</b-card-text>
						<b-button href="/app" variant="primary">
							<i class="fas fa-chart-pie"></i> App
						</b-button>
					</b-card>
				</div>
				<div class="col-12 col-md-6 mb-2 text-center">
					<b-card header="Forum" header-tag="header">
						<b-card-text
							>Join in the conversations at<br />the iAMconnected Forum</b-card-text
						>
						<b-button href="/forum" variant="primary">
							<i class="fas fa-comments"></i> Forum
						</b-button>
					</b-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Profile from '../components/Profile.vue';
/*import axios from 'axios';*/

export default {
	name: 'authDashboard',
	components: {
		Profile,
	},
	data() {
		return {};
	},
	computed: {},
	methods: {},
	mounted() {},
	watch: {
		/*$route(to) {
			this.page_loaded = 0;
			this.section_title = to.meta.title;
			this.page_title = to.meta.title;
			this.getUserDashboardCards();
		},*/
	},
};
</script>

<style scoped></style>
