<template>
	<div>
		<b-form-select
			v-model="selected"
			:options="tzIntsHours"
			@change="updateTimezone"
		></b-form-select>
	</div>
</template>

<script>
export default {
	name: 'timezoneSelect',
	components: {},
	props: {
		profile: {
			type: Object,
		},
	},
	data() {
		return {
			tzIntsMins: [],
			tzIntsHours: [
				{ text: '(GMT-12:00) International Date Line West', value: '-12' },
				{ text: '(GMT-11:00) Midway Island, Samoa', value: '-11' },
				{ text: '(GMT-10:00) Hawaii', value: '-10' },
				{ text: '(GMT-09:00) Alaska', value: '-9' },
				{ text: '(GMT-08:00) Pacific Time (US & Canada)', value: '-8' },
				{ text: '(GMT-08:00) Tijuana, Baja California', value: '-8' },
				{
					text: '(GMT-07:00) Arizona, Chihuahua, La Paz, Mazatlan, Mountain Time (US & Canada)',
					value: '-7',
				},
				{ text: '(GMT-06:00) Central America, Central Time (US & Canada)', value: '-6' },
				{
					text: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco, Eastern Time (US & Canada), Indiana (East)',
					value: '-5',
				},
				{
					text: '(GMT-04:00) Atlantic Time (Canada), Caracas, La Paz, Manaus, Santiago',
					value: '-4',
				},
				{ text: '(GMT-03:30) Newfoundland', value: '-3.5' },
				{
					text: '(GMT-03:00) Brasilia, Buenos Aires, Georgetown, Greenland, Montevideo',
					value: '-3',
				},
				{ text: '(GMT-02:00) Mid-Atlantic', value: '-2' },
				{ text: '(GMT-01:00) Azores, Cape Verde Is.', value: '-1' },
				{
					text: '(GMT+00:00) Casablanca, Monrovia, Reykjavik, Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
					value: '0',
				},
				{
					text: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana',
					value: '1',
				},
				{
					text: '(GMT+01:00) Prague, Brussels, Copenhagen, Madrid, Paris, Sarajevo, Skopje, Warsaw, Zagreb, West Central Africa',
					value: '1',
				},
				{
					text: '(GMT+02:00) Amman, Athens, Beirut, Bucharest, Cairo, Istanbul, Harare',
					value: '2',
				},
				{
					text: '(GMT+02:00) Pretoria, Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius, Jerusalem, Minsk, Windhoek',
					value: '2',
				},
				{
					text: '(GMT+03:00) Kuwait, Riyadh, Baghdad, Moscow, St. Petersburg, Volgograd, Nairobi, Tbilisi',
					value: '3',
				},
				{ text: '(GMT+03:30) Tehran', value: '3.5' },
				{ text: '(GMT+04:00) Abu Dhabi, Muscat, Baku, Yerevan', value: '4' },
				{ text: '(GMT+04:30) Kabul', value: '4.5' },
				{ text: '(GMT+05:00) Yekaterinburg, Islamabad, Karachi, Tashkent', value: '5' },
				{
					text: '(GMT+05:30) Sri Jayawardenapura, Chennai, Kolkata, Mumbai, New Delhi',
					value: '5.5',
				},
				{ text: '(GMT+05:45) Kathmandu', value: '5.75' },
				{ text: '(GMT+06:00) Almaty, Novosibirsk, Astana, Dhaka', value: '6' },
				{ text: '(GMT+06:30) Yangon (Rangoon)', value: '6.5' },
				{ text: '(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk', value: '7' },
				{
					text: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi, Kuala Lumpur, Singapore, Irkutsk, Ulaan Bataar, Perth, Taipei',
					value: '8',
				},
				{ text: '(GMT+09:00) Osaka, Sapporo, Tokyo, Seoul, Yakutsk', value: '9' },
				{ text: '(GMT+09:30) Adelaide', value: '9.5' },
				{ text: '(GMT+09:30) Darwin', value: '9.5' },
				{
					text: '(GMT+10:00) Brisbane, Canberra, Melbourne, Sydney, Hobart, Guam, Port Moresby, Vladivostok',
					value: '10',
				},
				{ text: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: '11' },
				{
					text: '(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka, Marshall Is.',
					value: '12',
				},
				{ text: "(GMT+13:00) Nuku'alofa", value: '13' },
			],
		};
	},
	computed: {
		selected: {
			get() {
				return this.profile.timezoneOffsetH;
			},
			set(val) {
				console.log(val);
			},
		},
	},
	mounted() {
		//this.selected = this.profile.timezoneOffsetH;
	},
	methods: {
		convertHourstoMins() {
			for (const obj of Object.values(this.tzIntsHours)) {
				var val = 0;
				if (obj.value < 0) {
					val = obj.value * 60;
					val = -Math.abs(val);
				} else {
					val = obj.value * 60;
				}

				obj.value = val;

				this.tzIntsMins.push(obj);
			}
		},
		updateTimezone(data) {
			/*this.$store.commit("profileTimezoneSet", data);*/
			this.profile.timezoneOffsetH = data;
			this.$emit('changed', this.profile.timezoneOffsetH);
		},
	},
};
</script>

<style scoped></style>
