<template>
<div class="container" id="Error404_wrap">
	
	<div class="text-center text-danger mt-5">
		<h4>Page is Missing....</h4>
	</div>
	

</div>
</template>


<script>

export default {
	name: "Error404",
	props: {
	},
	data() {
		return {
		}
	},
	computed:{
	},
	methods:{
	},
	mounted() {
	},
	watch: {
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
