<template>
	<div class="container" id="settings_wrapper">
		<div class="">
			<b-list-group v-if="settings.notifications !== null" class="">
				<h5 class="gold">Notifications</h5>
				<b-list-group-item v-for="item in settings.notifications" :key="item.var" class="">
					<b-form-checkbox
						v-model="item.val"
						name="check-button"
						switch
						@change="updateSetting(item)"
					>
						<span v-text="item.text"></span>
					</b-form-checkbox>
				</b-list-group-item>
			</b-list-group>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Settings',
	components: {},
	data() {
		return {
			errors: {
				loading: '',
			},
			settings: {
				notifications: null,
			},
		};
	},
	computed: {
		profile() {
			return this.$store.state.profile;
		},
	},
	methods: {
		userSettingsGet() {
			let _this = this;
			let params = new URLSearchParams();
			params.append('token', this.$store.getters.userTokenGet);
			params.append('user_id', this.profile.id);

			axios({
				url: _this.$store.state.env.api_url + 'userSettingsGet',
				params: params,
			})
				.then(function (response) {
					_this.errors.loading = response.data.message;

					if (response.data.status === 1) {
						if (response.data.settings.notifications) {
							_this.settings.notifications = response.data.settings.notifications;
						}
					}
				})
				.catch(function (e) {
					console.log('Error userSettingsGet:' + e);
				});
		},
		updateSetting(item) {
			let _this = this;
			let params = new URLSearchParams();
			params.append('token', this.$store.getters.userTokenGet);
			params.append('user_id', this.profile.id);

			const I = Object.entries(item);

			for (const [key, val] of I) {
				if (key !== 'text') {
					params.append('item[' + key + ']', val);
				}
			}

			axios({
				url: _this.$store.state.env.api_url + 'userSettingsUpdate',
				params: params,
			})
				.then(function (response) {
					console.log(response.data);
				})
				.catch(function (e) {
					console.log('Error updateSetting:' + e);
				});
		},
	},
	mounted() {
		this.userSettingsGet(this.$store.state.profile);
	},
	watch: {
		profile: {
			deep: true,
			handler(v) {
				if (v.id) {
					this.userSettingsGet(this.$store.state.profile);
				}
			},
		},
	},
};
</script>

<style scoped></style>
